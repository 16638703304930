<template>
  <RouterView></RouterView>
</template>

<script>

export default {
  name: 'App',
  components:{
  },
}
</script>

<style>
</style>
