<template>
    <div>
        <ul v-for="x in list" :key="x" class="list-unstyled text-center"  v-bind:class = "(x.name<coinTresh)?'coins':'bills'">
                <li class="list-unstyled text-center" v-for="y in x.times" :key="y">
                    <img :src="getNote(x.name)"/>
                </li>     
        </ul>
    </div>
</template>

<script>
export default {
    name:"MoneyList",
    props:["list", "currency", "coinTresh"],
    data() {
        return {
            defImage: require(`@/assets/img/default.png`)
        }
    },
    methods: {
        getNote(name) {
            let note=0
            try {
                note=require(`@/assets/img/money/${this.currency}/${String(name).replace(".",")")}.png`) 
            } catch (error) {
                return this.defImage
            }
            
            return note
        },
        setAltImg(){
            alert("Juhū")
        }
    },

}
</script>

<style >
@import url("@/assets/bootstrap/css/bootstrap.min.css");
@import url("@/assets/css/Cabin%20Sketch.css");
@import url("@/assets/css/Neucha.css");
@import url("@/assets/css/styles.css");

</style>