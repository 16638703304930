<template>
    <div id="body" :style="{'background-position-x':formValues.workingHours*10+'px'}">
    <div class="container" style="background: #ffffff;text-align: center;padding: 12px;">
      <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6"><img src="@/assets/img/logo.png" id="logo" alt=""></div>
          <div class="col-sm-12 col-md-6 col-lg-6" style="padding: 20px;">
            <p>There should be something about who made this...</p>
          </div>
      </div>
      </div>
      <div class="container" style="background: #ffffff;padding: 20px;margin-top: 23px;">
          <form @submit.prevent="submitForm">
  
              <div class="row" style="margin-bottom: 20px;">
                  <h2 class="col-md-2 col-sm-12 col-lg-1" style="vertical-align: center;">Salary:</h2>
                  <div class="col">
                    <input :style="{'font-size':inputFontSize}" class="form-control" type="text" v-model="formValues.wage">
                  </div>
                  <h2 class="col-md-2 col-sm-12">Currency:</h2>
                  <div class="col-md-2 col-xl-1 col-xxl-1"><select class="form-select" style="text-align: center;" v-model="formValues.currency">
                          <option value="dol" selected="">$</option>
                          <option value="eur">€</option>
                      </select></div>
              </div>
              <div class="row">
                <h2 class="col-lg-3 col-md-4 col-sm-12">Workind day's length:</h2>
                  <div class="col align-self-center">
                    <input class="form-range form-control align-self-center" type="range" v-model="formValues.workingHours" min="1" max="24">
                  </div>
                  <div class="col-1">
                      <p class="fs-3 text-center d-xl-flex my-auto justify-content-xl-center">{{ formValues.workingHours }}h</p>
                  </div>
              </div>
              <input value="Submit" class="btn btn-primary" type="submit" style="width: 100%;font-size: 31px;text-align: center;padding-top: 12px;">
          </form>
      </div>
      
      
      <div v-if="curValues.set" class="container" style="margin-top: 30px;background: #ffffff;">
          <h2 style="text-align: center;">You earn per</h2>
          <div class="row">
              
            <div class="col">
                  <h4 style="text-align: center;">Day</h4>
                  <p>{{ periods.day }} {{ getSign() }}</p>
                  <MoneyList :list="calcMoneyValuesList2(periods.day)" :currency="curValues.currency" :coin-tresh="currencies[curValues.currency].coinTresh"/>
              </div>
              
              <div class="col" style="text-align: center;">
                  <h4 style="text-align: center;">Hour</h4>
                  <p>{{ periods.hour }} {{ getSign() }}</p>
                  <MoneyList :list="calcMoneyValuesList2(periods.hour)" :currency="curValues.currency" :coin-tresh="currencies[curValues.currency].coinTresh"/>
              </div>
              <div class="col" style="text-align: center;">
                  <h4 style="text-align: center;">Minute</h4>
                  <p>{{ periods.minute }} {{ getSign() }}</p>
                  <MoneyList :list="calcMoneyValuesList2(periods.minute)"  :currency="curValues.currency" :coin-tresh="currencies[curValues.currency].coinTresh"/>
              </div>
              <div class="col" style="text-align: center;">
                  <h4 style="text-align: center;">Second</h4>
                  <p>{{ periods.second }} {{ getSign() }}</p>
                  <MoneyList :list="calcMoneyValuesList2(periods.second)"  :currency="curValues.currency" :coin-tresh="currencies[curValues.currency].coinTresh"/>
              </div>
              <div class="col" style="text-align: center;">
                  <h4 style="text-align: center;">While being here</h4>
                  <p>{{timerValue}} {{ getSign() }}</p>
                  <MoneyList :list="calcMoneyValuesList2(timerValue)"  :currency="curValues.currency" :coin-tresh="currencies[curValues.currency].coinTresh"/>
              </div>
          </div>
      </div >
    <p style="margin-top: 10px;color: white;">Version: {{ VERSION }}</p>
    </div>
  </template>
  
  <script>
  
  import MoneyList from '../components/MoneyList.vue';
  
  export default {
    name: 'App',
    components:{
      MoneyList
    },
    data() {
      return{
        VERSION:0.5,
        test:"red",
        daysInMonth: 21.4,
        formValues : {
          wage: 2000,
          currency: "eur",
          workingHours: 8,
        },
        curValues : {
          set: false,
          wage: 0,
          currency: "eur",
          workingHours: 0,
        },
        periods :{
          day:  0,
          hour: 0,
          minute: 0,
          second: 0,
          unbiasedSecond: 0
        },
        currencies:{
          "dol":{
            sign:"$",
            values:[100,50,20,10,5,2,1,0.25,0.1,0.05,0.01],
            coinTresh:1
          },
          "eur":{
            sign:"€",
            values:[30000,7000,500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5, 0.20, 0.10, 0.05, 0.02, 0.01],
            coinTresh:5
          }
        }
        ,
        lastTimestamp:0,
        timer:{
          interval: setInterval(this.updateTimer,50),
          timePassed: 0
        },
      }
    },
    methods: {
      daysInThisMonth() {
        var now = new Date();
        return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
      },
      submitForm(){
        this.curValues.set=true
        this.curValues.wage=this.formValues.wage
        this.curValues.currency=this.formValues.currency
        this.curValues.workingHours=this.formValues.workingHours
  
        this.lastTimestamp=Date.now()
        this.updateTimer()
  
        this.calcPeriods()
      },    
      roundTill(number,precision=2,direction="up"){
        if (direction==="up"){
  
          return Math.ceil(number*Math.pow(10,precision))/Math.pow(10,precision)
        }
      },
      calcThisPer(wage, divider, round=true){
        const divided=wage/divider
        if(round){
          return this.roundTill(divided,2,"up")
        }else{
        return divided}
      },
      calcPeriods(){
        this.periods.day= this.calcThisPer(this.curValues.wage, this.daysInMonth)
        this.periods.hour= this.calcThisPer(this.curValues.wage, this.daysInMonth*this.curValues.workingHours)
        this.periods.minute= this.calcThisPer(this.curValues.wage, this.daysInMonth*this.curValues.workingHours*60)
        this.periods.second= this.calcThisPer(this.curValues.wage, this.daysInMonth*this.curValues.workingHours*60*60)
        this.periods.unbiasedSecond= this.calcThisPer(this.curValues.wage, this.daysInMonth*this.curValues.workingHours*60*60,false)
      },
      updateTimer(){
        this.timer.timePassed=((Date.now()-this.lastTimestamp)/1000).toFixed(2)
      },
      getSign(){
        return this.currencies[this.curValues.currency].sign
      },    
      calcMoneyValuesList(amount){
        let valuesList=[];
        let amountLeft=amount
        let currencyValues=this.currencies[this.curValues.currency].values
        
        for (const w of currencyValues){
          let wholeTimes = Math.floor(amountLeft/w)
          amountLeft=amountLeft%w
          if (wholeTimes>0)
            valuesList.push({name:w,times:wholeTimes});
        }
        return valuesList
      },
      calcMoneyValuesList2(amount, values=this.currencies[this.curValues.currency].values) {
        let mp=100
        
        let newAmount=Math.round(amount*mp)
        let subAmount=newAmount
        let checkAmount=0
  
        let valuesList = []
  
        values.forEach(v => {
            v=v*mp
            let strongDiv=Math.floor(subAmount/v)
            let sub = strongDiv*v
  
            subAmount-=sub
            checkAmount+=sub
  
            if (sub>0) {
                valuesList.push({"name":v/mp,"times":strongDiv})
            }
            
        }); 
        if (checkAmount!=newAmount) {
            console.log("Error with number "+amount);
        }
        return valuesList
      }
    },
    computed:{
        timerValue(){
          return this.roundTill(this.timer.timePassed*this.periods.unbiasedSecond)
        },
        backgroundImage() {
          if(this.curValues.set){
            return `url(${require(`@/assets/img/${this.curValues.currency}Pattern.png`)})`
          }else{
            return `url(${require(`@/assets/img/${this.formValues.currency}Pattern.png`)})`
          }
        },
        inputFontSize(){
        return `${String(this.formValues.wage).length+18}px`
      },
      animationSpeed(){
        return `${24/this.formValues.workingHours}s`
      },
      shadowX(){
        return `${-(this.formValues.workingHours-12)}px 15px 15px -3px rgba(0,0,0,0.2)`
      }
      }
  }
  </script>
  
  <style>
  
  @keyframes loopBCG {
    from {background-position-y: 0;}
    to {background-position-y: 200px;}
  }
  
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  @import '../assets/bootstrap/css/bootstrap.min.css';
  @import '../assets/css/Cabin%20Sketch.css';
  @import '../assets/css/Neucha.css';
  @import '../assets/css/styles.css';
  
  #bigBoy{
    background-color: rgb(80, 80, 80)
  }
  #body{
    background-image: v-bind(backgroundImage);
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 100vh;
    background-size: 200px;
  
    animation-name: loopBCG;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .container {
    border-radius: 17px;
    box-shadow: v-bind(shadowX);
  }
  #logo{
    width: 100%;
  }
  </style>